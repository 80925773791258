* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f8f8f8;
  div {
    main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      align-items: center;
    }
  }
  .auto-complete:first-child {
    margin-top: 20px;
  }
  .auto-complete {
    margin-top: 10px;
  }
}
